import Vue from 'vue/dist/vue.esm'
import PublicHeader from '../public-header.vue'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#public-header',
    data: {
    },
    components: { PublicHeader }
  })
})
