<template>
  <header class="header">
    <div class="logo">
      <a :href="rootPath">
        <img :src="logo">
        <span class="header-site-name">
          EggSaurus
        </span>
      </a>
    </div>
    <div>
      <a :href="shopPath" class="header-menu-btn">SHOP</a>
      <a :href="fernPath" class="header-menu-btn">$FERN</a>
      <span @click="connectWallet" class="header-menu-btn">CONNECT</span>
    </div>
    <div class="header-links">
      <span>
        <a href="https://twitter.com/EggSaurus_NFT" target="_blank" rel="noopener noreferrer" class="header-link">
          <img :src="twitter" class="header-image">  
        </a>
      </span>
      <span>
        <a href="https://discord.gg/H9Cu5xrJUH" target="_blank" rel="noopener noreferrer" class="header-link">
          <img :src="discord" class="header-image">
        </a>
      </span>
      <div class="dropdown">
        <button class="btn btn-sm dropdown-toggle language-btn" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          <template v-if="locale == 'ja'">
            日本語
          </template>
          <template v-else>
            English
          </template>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li><a class="dropdown-item" href="/?locale=en">English</a></li>
          <li><a class="dropdown-item" href="/?locale=ja">日本語</a></li>
        </ul>
      </div>
    </div>

    <!-- modal -->
    <div v-if="modalStatus" class="modal-fixed-wrapper" @click="closeModal">
      <div class="modal-container" @click.stop.prevent>
        <div class="modal-desc">
          {{ modalMessage }}
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'packs/axios';
import { ethers } from "ethers";
import logo from 'images/header-logo.png';
import twitter from 'images/twitter-black.svg';
import discord from 'images/discord-black.svg';

export default {
  components: {
    
  },
  data: function() {
    return {
      locale: "en",
      logo,
      twitter,
      discord,
      walletAddress: "",

      // modal
      modalMessage: "",
      modalStatus: false,
    }
  },
  created() {
    const element = document.getElementById("public-header");
    const data = JSON.parse(element.getAttribute('data'));
    this.locale = data.locale;
  },
  mounted () {
  },
  computed: {
    shopPath: function() {
      if(this.locale == "ja") {
        return "/shop?locale=ja"
      }else{
        return "/shop?locale=en"
      }
    },
    fernPath: function() {
      if(this.locale == "ja") {
        return "/fern?locale=ja"
      }else{
        return "/fern?locale=en"
      }
    },
    rootPath: function() {
      if(this.locale == "ja") {
        return "/?locale=ja"
      }else{
        return "/?locale=en"
      }
    }
  },
  methods: {
    connectWallet: async function() {
      let self = this;
      if(window.ethereum){
        await window.ethereum.send('eth_requestAccounts');
      }
      let provider;
      if(typeof web3 != 'undefined'){
        provider = new ethers.providers.Web3Provider(web3.currentProvider);
      }else if(typeof window.ethereum != 'undefined'){
        provider = new ethers.providers.Web3Provider(window.ethereum);
      }
      
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const signature = await signer.signMessage("Agree with the terms of service. https://eggsaurus.com/terms");
      const params = {signature: signature, address: address}

      axios.post("/wallet_login", params)
      .then(function(res) {
        if(res.data.error_message){
          self.openModal(res.data.error_message);
        }else{
          window.location.href = "/shop";
        }          
      })
    },

    // modal
    openModal: function(errorMessage) {
      this.modalMessage = errorMessage;
      this.modalStatus = true;
    },
    closeModal: function() {
      this.modalStatus = false;
    },
  },
}
</script>

<style scoped lang="scss">
  
</style>
